import {css} from 'styled-components';

function camelCaseToDash(myStr) {
  return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export default css`
  ${({theme}) =>
    Object.entries(theme.colors).map(
      ([name, color]) => css`
        .background--${camelCaseToDash(name)} {
          background: ${color};
        }
      `
    )};
`;
