import {useI18next} from 'gatsby-plugin-react-i18next';
import {useCallback, useMemo} from 'react';

const DEFAULT_LANG = 'en';

const $createTransCallback = (translated, currentLanguage, data, key) => () => {
  if (currentLanguage === DEFAULT_LANG || !translated) return data;

  const activeTranslated = translated.find(
    ({locale}) => locale.id.replace(/_.+$/, '') === currentLanguage
  );
  return (activeTranslated && activeTranslated[key]) || data;
};

const useCurrentContent = ([translated, content]) => {
  const {language: currentLanguage} = useI18next();

  return useCallback(
    $createTransCallback(translated, currentLanguage, content, 'content'),
    [translated]
  );
};

export const useCurrentTitle = ([translated, title]) => {
  const {language: currentLanguage} = useI18next();

  return useCallback(
    $createTransCallback(translated, currentLanguage, title, 'title'),
    [translated]
  );
};

export const useCurrentSeo = ([translated, seo]) => {
  const {language: currentLanguage} = useI18next();

  return useCallback(
    $createTransCallback(translated, currentLanguage, seo, 'seo'),
    [translated]
  );
};

export const useAuthorDescription = (language, author) => {
  const description = useMemo(() => {
    if (author.node && author.node.description) {
      return author.node.description;
    }
    return '';
  }, [author]);

  const descriptionEs = useMemo(() => {
    if (author.node && author.node.descriptionEs) {
      return author.node.descriptionEs;
    }
    return '';
  }, [author]);

  const descriptionFr = useMemo(() => {
    if (author.node && author.node.descriptionFr) {
      return author.node.descriptionFr;
    }
    return '';
  }, [author]);

  const descriptionIt = useMemo(() => {
    if (author.node && author.node.descriptionIt) {
      return author.node.descriptionIt;
    }
    return '';
  }, [author]);

  const descriptionPt = useMemo(() => {
    if (author.node && author.node.descriptionPt) {
      return author.node.descriptionPt;
    }
    return '';
  }, [author]);

  const descriptionMapping = {
    es: descriptionEs,
    it: descriptionIt,
    fr: descriptionFr,
    pt: descriptionPt,
  };

  if (!descriptionMapping[language]) {
    return description;
  } else {
    return descriptionMapping[language];
  }
};

export const useRelatedPosts = ([translated, relatedPost]) => {
  const {language: currentLanguage} = useI18next();

  return useCallback(
    $createTransCallback(
      translated,
      currentLanguage,
      relatedPost,
      'relatedArticle'
    ),
    [translated]
  );
};

export const useCurrentCategories = ([
  translated,
  categories,
  categoriesUri,
]) => {
  const {language: currentLanguage} = useI18next();

  const defaultCategoryUris = categoriesUri.map(uri =>
    uri.replace(/\/[a-zA-Z]{2}\//, '/')
  );

  const getCurrentCategory = useCallback(
    $createTransCallback(
      translated,
      currentLanguage,
      categories,
      'translatedCategoryName'
    ),
    [translated]
  );

  const category = getCurrentCategory();
  const categoryUri = defaultCategoryUris;

  return {category, categoryUri};
};

export default useCurrentContent;
