import {css} from 'styled-components';
import buttonStyles from './button';
import {respondTo} from '../../core/mixins';
import skeletonBox from './skeleton-box';
import colors from './colors';

export const pricingStyles = css`
  ${buttonStyles}
  ${skeletonBox}
  ${colors}

  &.pricing {
    ${colors}
    .header-section {
      padding-top: 46px;
      padding-bottom: 46px;

      .header-content {
        min-height: 150px;
        display: block;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        .header-content__title {
          width: 100%;
          margin-bottom: 16px;

          > h1 {
            margin-left: 0px;
          }
        }

        .header-content__description {
          width: 100%;

          ${respondTo.lg(css`
            width: 50%;
          `)}
          padding-left: 0px;
          font-weight: 500;
          font-size: 20px;

          a {
            color: ${({theme}) => theme.colors.primaryDark};
          }
        }
      }
    }

    .Btn.Plans__Btn--starter-bg {
      background: ${({theme}) => theme.colors.primaryLighter2};
    }

    .iframe-container {
      background-color: ${({theme}) => theme.colors.neutral100};
      display: flex;
      justify-content: center;

      &.is-loading > div {
        &::before {
        }
      }

      > div {
        min-height: 882px;
        margin: 0 25px;
        max-width: 1440px;
        position: relative;
        width: 100%;

        iframe {
          margin-top: -50px;
          display: none;
        }
      }
    }

    .Plans__PlansDetailsTable {
      max-height: 0;
      transition: 0.3s max-height ease-in-out, 0.3s opacity ease-in-out;

      max-height: 9999px;
      opacity: ${({headerIsVisible}) => (headerIsVisible ? '1' : '0')};

      z-index: 99;
      pointer-events: ${({headerIsVisible}) =>
        headerIsVisible ? 'auto' : 'none'};
      position: fixed;
      top: 0;
      width: 100%;

      .Table {
        background: white;
        margin: 0 auto;
        max-width: 1440px;
        width: 100%;
        position: relative;
        overflow: hidden;
        overflow-x: auto;
        background-color: $white;

        table {
          border-spacing: 0;
          margin-bottom: 0;
          table-layout: fixed;
          min-width: 100%;

          th {
            text-align: left;
            padding-left: 12px;
          }

          tr {
            height: 73px;
            th {
              border-bottom: 2px solid $component-border;
              background-color: ${({theme}) => theme.colors.gray5};
              color: $gray-1;
              font-size: 20px;
              font-weight: 500;
              text-transform: capitalize;
            }
            &.TableHead {
              height: 232px;
              box-shadow: 0 6px 4px 0 $gray-1;
              td {
                &.TableHeadTitle {
                  h2 {
                    text-transform: capitalize;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 32px 12px;
                    font-size: 32px;
                    color: ${({theme}) => theme.colors.primary};
                    font-weight: 500;
                  }
                }
                border-right: none;
                text-align: center;
                width: 20%;
                &:first-of-type {
                  text-align: left;
                  padding: 0;
                  position: relative;
                  > img {
                    top: 56px;
                    position: absolute;
                    width: 64px;
                    left: 52px;
                  }
                }
                .Plans__TableHeadName {
                  margin-bottom: 18px;
                  margin-top: 2px;
                  h3 {
                    text-transform: capitalize;
                    color: $gray-1;
                    font-size: 32px;
                    margin-bottom: 0;
                    font-weight: 500;
                  }

                  &.Plans__TableHeadName--starter {
                    h3 {
                      color: ${({theme}) => theme.colors.primaryLighter2};
                    }
                  }
                  &.Plans__TableHeadName--event {
                    h3 {
                      color: ${({theme}) => theme.colors.secondary};
                    }
                  }
                  &.Plans__TableHeadName--scale {
                    h3 {
                      color: ${({theme}) => theme.colors.primary};
                    }
                  }
                  &.Plans__TableHeadName--custom {
                    h3 {
                      color: ${({theme}) => theme.colors.secondaryDark};
                    }
                  }
                }
                .Plans__TableHeadPrice {
                  margin-bottom: 26px;
                  p {
                    color: $gray-3;
                    font-size: 24px;
                  }
                }
                .Plans__TableHeadButton {
                  width: 200px;
                  margin: 0 auto;
                  display: block;

                  a {
                    display: block;
                  }
                }
              }
            }

            td {
              border: 2px solid $component-border;
              border-top: none;
              border-left: none;
              text-align: center;

              font-size: 24px;
              &:last-child {
                border-right: none;
              }
              .CheckImage {
                margin-top: 5px;
              }
              &:not(:first-of-type) {
                color: $gray-3;
                font-weight: 400;
              }
            }
            &:first-of-type > th {
              border-top: 2px solid $component-border;
            }
            &:not(:first-of-type) td:first-of-type {
              text-align: left;
              padding: 0 12px;
            }
          }
        }
      }

      .HelpText {
        display: none;
        p {
          color: $violet;
          font-size: 16px;
          margin: 34px 0 0;
        }
      }
    }

    .Plans__PlansDetailsTable--visible {
      max-height: 9999px;
      opacity: 1;
      .HelpText {
        display: block;
      }
    }

    .section-bestplan {
      margin: 5em 0.7em 4em 0.7em;

      & > h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        color: ${({theme}) => theme.colors.gray1};
        margin-bottom: 1.5em;
      }

      .bestplan-boxes {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 40px;
      }

      @media (max-width: 767px) {
        .section-bestplan {
          margin-top: 3em;
          margin-bottom: 4em;
        }
      }

      @media (max-width: 767px) {
        .section-bestplan h2 {
          font-size: 32px;
          line-height: 44px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      @media (max-width: 1024px) {
        .bestplan-boxes {
          grid-template-columns: 1fr 1fr;
        }
      }

      @media (max-width: 767px) {
        .bestplan-boxes {
          grid-template-columns: 1fr;
          grid-row-gap: 24px;
        }
      }

      .bestplan-box {
        background: #fff;
        border-radius: 4px;
        filter: drop-shadow(0px 2px 40px #c8d1e0);
        padding: 40px;
        width: 100%;

        & > h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 40px;
          color: ${({theme}) => theme.colors.gray1};
          margin: 19px 0 16px 0;
        }

        p {
          min-height: 88px;
          width: 86%;
        }

        @media (max-width: 767px) {
          .bestplan-box h3 {
            font-size: 24px;
          }
          .bestplan-box .btn {
            font-size: 18px;
          }
        }

        .bestplan-box-img-wrapper {
          img {
            height: 36.66px;
            width: auto !important;
          }
        }

        .btn {
          padding: 10px 18px;
          border-radius: 5px;
          color: #fff;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
        }

        .bestplan-box-footer {
          display: flex;
          justify-content: space-between;
        }
        .btn-1 {
          background: ${({theme}) => theme.colors.primary};
        }
        .btn-2 {
          background: ${({theme}) => theme.colors.secondary};
        }
        .btn-3 {
          background: ${({theme}) => theme.colors.secondaryDark};
        }
        .bg-grey-2 {
          background: ${({theme}) => theme.colors.gray10};
        }

        .bg-grey-3 {
          background: ${({theme}) => theme.colors.gray1};
        }
        .bg-grey-4 {
          background: ${({theme}) => theme.colors.gray10};
        }
      }
    }

    .section-whydacast {
      display: grid;
      align-items: center;
      grid-template-columns: 0.9fr 1fr;
      grid-column-gap: 60px;
      margin: 80px 10px 70px 10px;

      .whydacast-info {
        & > h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 56px;
          line-height: 72px;
          color: #fff;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 38px;
          color: #fff;
          margin-bottom: 0;
        }

        .btn {
          border-radius: 4px;
          background: #4967ee;
          color: #fff;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 25px;
          padding: 12px 36px;
          display: inline-block;
          margin-top: 80px;
        }
      }

      @media (max-width: 767px) {
        .whydacast-info h2 {
          font-size: 48px;
          line-height: 56px;
        }
      }

      @media (max-width: 767px) {
        .whydacast-info p {
          font-size: 21px;
          line-height: 28px;
        }
      }

      @media (max-width: 767px) {
        .whydacast-info .btn {
          margin-top: 40px;
        }
      }

      @media (max-width: 1024px) {
        .whydacast-info p {
          max-width: 100%;
        }
      }

      .whydacast-boxes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 40px;

        .whydacast-box {
          background: #fff;
          border-radius: 8px;
          padding: 24px;
          width: 100%;

          .whydacast-box--image {
            background: linear-gradient(120.6deg, #6d85f1 0%, #7048e8 95.66%);
            border-radius: 100%;
            width: 44px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            margin-bottom: 16px;
          }

          & > h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: ${({theme}) => theme.colors.gray1};
          }

          a {
            color: ${({theme}) => theme.colors.primary};
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: ${({theme}) => theme.colors.gray1};
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
      @media (max-width: 767px) {
        .whydacast-boxes {
          grid-template-columns: 1fr;
          grid-row-gap: 0px;
        }
      }
    }
    .section-carrousel div > div:nth-child(2) > ul > li {
      max-height: 181px;
    }
    ////
    @media (min-width: 1025px) and (max-width: 1300px) {
      .section-whydacast {
        grid-template-columns: 0.55fr 1fr;
        margin: 80px 60px;
      }
    }
    @media (max-width: 1024px) {
      .section-whydacast {
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
      }
    }
    @media (max-width: 768px) {
      .section-whydacast {
        margin: 80px 40px;
      }
    }
    @media (max-width: 480px) {
      .section-whydacast {
        margin: 60px 25px;
      }
    }
    ////

    .section-faq {
      max-width: 1069px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5em;
      margin-bottom: 6em;

      h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 56px;
        color: #222f3e;
        text-align: center;
      }

      .accordion-faq {
        margin-top: 4em;

        .accordion-item-faq {
          border-bottom: 1px solid #c8d1e0;
        }

        button[aria-expanded='true'] .icon-faq:after {
          -moz-transform: scaleY(-1);
          -o-transform: scaleY(-1);
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
          filter: FlipV;
          -ms-filter: 'FlipV';
          background-image: url('https://www.dacast.com/wp-content/uploads/2021/12/Polygon-2-1.svg');
          background-position: center center;
        }

        button {
          position: relative;
          display: block;
          text-align: left;
          width: 100%;
          padding: 1em 0;
          border: none;
          background: 0 0;
          outline: none;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #222f3e;
          padding-top: 20px;
          padding-bottom: 0;
          min-height: 0;
          margin-bottom: 10px;
        }

        button:hover,
        button:focus {
          cursor: pointer;
          color: #4967ee;
        }
        button:hover:after,
        button:focus:after {
          cursor: pointer;
          color: #4967ee;
          border: 1px solid #4967ee;
        }
        button .accordion-title-faq {
          padding: 0 60px 0 12px;
          text-transform: none;
          letter-spacing: 0;
          display: inline-block;
        }
        button .icon-faq {
          display: inline-block;
          position: absolute;
          top: 18px;
          right: 24px;
          width: 22px;
          height: 22px;
        }
        button .icon-faq:before {
          display: block;
          position: absolute;
          content: '';
          top: 9px;
          left: 5px;
          width: 23px;
          height: 14px;
          background-image: url('https://www.dacast.com/wp-content/uploads/2021/12/Polygon-2-1.svg');
          background-position: center center;
        }
        button[aria-expanded='true'] .icon-faq:before {
          display: none;
        }
        button .icon-faq:after {
          display: block;
          position: absolute;
          content: '';
          top: 5px;
          left: 5px;
          width: 23px;
          height: 14px;
        }
        .accordion-content-faq {
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: opacity 0.2s linear, max-height 0.2s linear;
          will-change: opacity, max-height;
        }

        button[aria-expanded='true'] + .accordion-content-faq {
          opacity: 1;
          max-height: 30em;
          transition: all 0.2s linear;
          will-change: opacity, max-height;
        }

        .accordion-content-faq p {
          font-size: 1rem;
          font-weight: 300;
          margin: 2em 0;
          padding-left: 12px;
          padding-right: 12px;
        }
      }
      .faq-contact {
        font-family: 'Roboto', Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #222f3e;
        text-align: center;
        margin-top: 2.5em;

        a {
          color: #4967ee;
        }
      }
    }

    .question-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 122px 0;

      .question-container__img-grid {
        min-width: 600px;
      }
      .question-container__img-grid,
      .question-container__questions-content {
        width: 50%;
      }

      .question-container__img-grid .grid-wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 30px;
        justify-items: center;
        align-content: center;
        width: 80%;
        min-width: 600px;
      }

      .questions-right__title h2 {
        text-align: center;
        color: ${({theme}) => theme.colors.primary};
        font-size: 2.5em;
        font-weight: 500;
        margin-top: 55px;
      }
      .questions-right__description {
        width: 100%;
        margin: 0 auto;

        p {
          text-align: center;
          font-size: 1.5em;
          font-weight: 500;
          color: ${({theme}) => theme.colors.gray1};
        }
      }

      .questions-right__button-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
      }
      .questions-right {
        width: 90%;
      }

      .btn.btn-trial {
        padding: 8px;
        margin: 20px 0;
        border-radius: 5px;
        color: #fff;
        text-align: center;
      }
      .btn.btn-trial h4 {
        margin: 0;
        font-size: 1.5em;
        color: #fff;
        font-weight: 500;
      }
    }

    .section-carrousel {
      margin-top: 5em;
      margin-bottom: 6em;
      margin-left: 14px;
      margin-right: 14px;

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 38px;
        color: ${({theme}) => theme.colors.gray1};
        text-align: center;
        margin-bottom: 32px;
      }
    }

    .components-section.bg-footer-image {
      position: relative;

      .components-section.bg-footer-image:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: black;
      }

      .components-section.bg-footer-image:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-top: 70px solid #fff;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        top: -2px;
      }

      .components-section.bg-footer-image > * {
        z-index: 1;
        position: relative;
      }
      .banner-footer__container {
        min-height: 650px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
      }
      .banner-footer__content {
        max-width: 600px;
      }

      .footer__content__title h2 {
        color: #fff;
        font-size: 4em;
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 1em;
      }
      .footer__content__description {
        color: #ffffff;
        font-size: 1em;
        text-align: center;
      }
      .footer__content__button {
        width: 60%;
        margin: 0 auto;
      }
    }
  }
`;
