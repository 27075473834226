import {css} from 'styled-components';
import {
  dacastBtn,
  dacastBtnLarge,
  dacastBtnJumbo,
  dacastBtnMedium,
  dacastBtnSmall2,
  dacastBtnPrimary,
  dacastBtnSecondary,
} from '../../core/Button/Button.mixins';
import {
  bold,
  subheading,
  heading02,
  heading05,
  heading02s,
  heading04s,
  heading05s,
  heading06s,
  medium,
  paragraph02,
  paragraph03,
  heading01,
  heading06,
  display02,
  heading03,
  caption,
  heading04,
  heading03s,
} from '../../core/Typography/Typography.mixins';

export const rioStyles = css`
  &.riocomponents {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul {
      color: inherit;
      font-size: inherit;
      margin: 0;
    }
    p {
      margin-bottom: 24px;
    }
    .gatsby-image-wrapper {
      div {
        max-width: 330px;
      }
    }
    .gatsby-image-wrapper img {
      height: auto !important;
    }
    .inline-gatsby-image-wrapper {
      max-width: 330px;
    }
    a {
      cursor: pointer;
    }
    section {
      padding: 0;
    }
    a {
      text-transform: capitalize;
      margin: 0;
    }
    .rio-hero {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;
      &.rio-hero--title {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: transparent;
        width: 100%;
        & > svg {
          background-color: transparent;
        }
      }
      &.rio-hero--subtitle {
        height: auto;
        background: transparent;
        text-align: center;
        > h6 > span,
        > h2 > span {
          color: var(--primary-500);
        }
      }
      &.rio-hero--caption {
        color: var(--shades-0);
        height: auto;
      }
      & > h1 {
        > span {
          color: var(--primary-500);
        }
      }

      @media (max-width: 767px) {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/HeroMobile.png');
        height: 369px;
        background-position: center;
        background-clip: content-box;
        margin-top: 48px;
        & > .rio-hero--title {
          margin-top: 15px;
          & > svg {
            max-width: 117px;
            max-height: 20px;
          }
          &.rio-hero--subtitle {
            & > h1 {
              display: block;
              ${heading06s}
              ${bold}
            }
            width: 200px;
            margin-top: 25px;
          }
          &.rio-hero--caption {
            width: 90%;
            margin-top: 93px;
            & > h4 {
              display: block;
              justify-content: center;
              ${paragraph03}
              ${medium}
            }
            & > a {
              ${dacastBtn}
              ${dacastBtnMedium}
              ${dacastBtnPrimary}
              margin-top: 24px;
            }
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/HeroDesktop-scaled.jpg');
        height: 804px;
        background-position: center;
        background-clip: padding-box;
        &.rio-hero--title {
          margin-top: 114px;
          & > svg {
            max-width: 327px;
            max-height: 54px;
          }
          &.rio-hero--subtitle {
            & > h1 {
              display: block;
              margin-top: 40px;
              ${heading02}
              ${bold}
            }
            width: 600px;
            margin-top: 25px;
          }
          &.rio-hero--caption {
            padding: 0 136px;
            margin-top: 171px;
            text-align: center;
            & > h4 {
              display: block;
              justify-content: center;
              margin-bottom: 48.95px;
              ${heading04s}
              ${medium}
            }
            & > a {
              ${dacastBtn}
              ${dacastBtnJumbo}
              ${dacastBtnPrimary}
            }
          }
        }
        .dacast-btn.dacast-btn--small.dacast-btn--primary {
          display: none;
        }
        .dacast-btn.dacast-btn--jumbo.dacast-btn--primary {
          margin-top: 48px;
        }
      }
      @media (min-width: 1024px) {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/HeroDesktop-scaled.jpg');
        height: 804px;

        background-position: center;
        background-clip: padding-box;
        &.rio-hero--title {
          max-width: 1200px;
          margin-top: 119px;
          & > svg {
            max-width: 327px;
            max-height: 54px;
          }
          &.rio-hero--subtitle {
            width: 457px;
            padding: 0;
            margin-top: 50px;
            & > h1 {
              display: block;
              margin-top: 40px;
              ${heading02}
              ${bold}
            }
          }
          &.rio-hero--caption {
            width: calc(100% - 103px);
            display: block;
            margin-top: 171px;
            margin-bottom: 20px;
            margin-left: 103px;
            justify-content: left;
            align-self: flex-start;
            & > h4 {
              max-width: 591px;
              margin-bottom: 48.95px;
              ${heading04s}
              ${medium}
            }
            & > a {
              ${dacastBtn}
              ${dacastBtnJumbo}
              ${dacastBtnPrimary}
              display: inline-block;
            }
          }
        }
      }
    }
    .rio-tiles {
      background-color: var(--shades-0);
      margin: 40px auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: auto;
      grid-row-gap: 24px;
      justify-items: center;
      padding: 0;

      .rio-tiles--tile {
        display: block;
        flex-direction: column;
        box-shadow: 0px -8px 32px rgba(255, 255, 255, 0.25),
          0px 8px 32px #e5e5e5;
        background: linear-gradient(116.55deg, #fbfbfb 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        text-align: center;
        border-radius: 24px;
        &.tile--icon---small {
          width: 32px;
          height: 32px;
        }
        @media (max-width: 767px) {
          margin: 0;
          height: 126px;
          width: 140px;
          padding: 12px;
          & > h4 {
            display: none;
          }
          i.tile--icon--big {
            display: none;
          }
        }
        @media (min-width: 768px) and (max-width: 1023px) {
          height: 270px;
          width: 270px;
          padding: 60px 16px;
          p {
            display: none;
          }
          i.tile--icon--small {
            display: none;
          }
        }
        @media (min-width: 1024px) {
          height: 270px;
          width: 270px;
          padding: 60px 16px;
          p {
            display: none;
          }
          i.tile--icon--small {
            display: none;
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1439px) {
        max-width: 560px;
        grid-column-gap: 24px;
      }
      @media (min-width: 1440px) {
        margin: 150px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
      }
    }
    .rio-cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      .cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        overflow: hidden;
        a {
          position: absolute;
          z-index: 10;
        }
      }
      @media (max-width: 767px) {
        margin-top: 40px;
        & > h2 {
          display: block;
          ${heading06s}
          ${bold}
          max-width: 300px;
        }
        & > h5 {
          display: block;
          ${paragraph03}
          max-width: 300px;
          margin: 16px 0;
        }
        .cta {
          margin-top: 24px;
          & > a {
            display: flex;
            top: 0px;
            ${dacastBtn}
            ${dacastBtnSmall2}
            ${dacastBtnPrimary}
          }
          .cta-img {
            content: url('https://www.dacast.com/wp-content/uploads/2022/03/CTAMobile.png');
            width: 100%;
            height: auto;
            margin-top: 8px;
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        margin-top: 80px;
        margin-bottom: -80px;
        & > h2 {
          display: block;
          ${heading01}
          ${medium}
          max-width: 754px;
        }
        & > h5 {
          display: block;
          ${heading05}
          ${medium}
          margin-top: 16px;
        }
        .cta {
          & > a {
            display: flex;
            top: 40px;
            position: absolute;
            ${dacastBtn}
            ${dacastBtnJumbo}
            ${dacastBtnPrimary}
          }
          .cta-img {
            content: url('https://www.dacast.com/wp-content/uploads/2022/03/CTADesktop.png');
            height: auto;
            transform: scale(1.3);
            object-fit: cover;
          }
        }
      }
      @media (min-width: 1024px) {
        margin-top: 80px;
        justify-content: center;
        text-align: center;
        margin-bottom: -160px;
        & > h2 {
          display: block;
          ${heading01}
          ${medium}
          max-width: 754px;
        }
        & > h5 {
          display: block;
          ${heading05}
          ${medium}
          margin: 16px 0;
        }
        .cta {
          margin-top: -160px;
          & > a {
            display: flex;
            top: 180px;
            position: absolute;
            ${dacastBtn}
            ${dacastBtnJumbo}
            ${dacastBtnPrimary}
          }
          .cta-img {
            content: url('https://www.dacast.com/wp-content/uploads/2022/03/CTADesktop.png');
            height: auto;
            object-fit: cover;
          }
        }
      }
    }
    .rio-features {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .rio-feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .rio-feature-main {
          display: flex;
          align-items: center;
          .rio-feature-content {
            display: flex;
            flex-direction: column;
            .rio-feature-title {
              text-align: center;
              margin: 0 auto;
            }
            .rio-feature-description {
            }
          }
          .rio-feature-img {
            display: block;
            position: relative;
            z-index: 1;
            max-height: 360px !important;
            min-height: 322px !important;
            & > div > div[data-placeholder-image] {
              max-height: 360px !important;
              min-height: 322px !important;
              overflow: hidden !important;
            }
            & > div > picture > img {
              max-height: 360px !important;
            }
            & > .gatsby-image-wrapper > img {
              min-height: 322px !important;
              max-height: 360px !important;
            }
            & > .feature-image-icon {
              position: absolute;
              z-index: 2;
              width: 133px !important;
              height: 133px !important;
              object-fit: contain !important;
            }
            &::after {
              content: ' ';
              display: flex;
              position: absolute;
              width: 175px;
              height: 175px;
              border-radius: 50%;
              z-index: -1;
            }
            &.copyright {
              & > .feature-image-icon {
                top: -35px;
                left: 0;
              }
              &::after {
                left: 70px;
                top: 0px;
                background: var(--primary-300);
                filter: blur(120px);
              }
            }
            &.experience {
              & > .feature-image-icon {
                top: -35px;
                left: -20px;
              }
              &::after {
                left: 70px;
                top: -20px;
                background: var(--primary-300);
                filter: blur(120px);
              }
            }
            &.stream {
              & > .feature-image-icon {
                top: -35px;
                left: -20px;
              }
              &::after {
                left: 70px;
                top: -20px;
                background: var(--primary-300);
                filter: blur(120px);
              }
            }
            &.china {
              & > .feature-image-icon {
                top: -10px;
                right: 0px;
              }
              &::after {
                left: 70px;
                top: -20px;
                background: var(--primary-300);
                filter: blur(120px);
              }
            }
          }
        }
        .rio-feature-bottom {
          .rio-feature-cta {
            text-align: center;
            .button-container {
              display: flex;
              margin-bottom: 24px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        margin: 0 16px;
        .rio-feature {
          margin: 80px auto 64px;
          .rio-feature-main {
            flex-direction: column;
            margin-top: 20px;
            .rio-feature-img {
              & > .feature-image {
                min-height: 320px !important;
              }
              & > .feature-image-icon {
                height: 62px !important;
                width: 62px !important;
              }
              &.copyright > .feature-image-icon {
                top: 0;
                left: 0;
              }
              &.experience > .feature-image-icon {
                top: 0;
                left: 0px;
              }
              &.stream > .feature-image-icon {
                top: 0;
                left: 0px;
              }
              &.china > .feature-image-icon {
                top: 0;
                right: 16px;
              }
            }
            .rio-feature-content {
              .rio-feature-title {
                position: absolute;
                top: -90px;
                left: 0;
                right: 0;
                text-align: center;
                ${heading04s}
                ${medium}
              }
              .rio-feature-description {
                ${paragraph03}
                ${medium}
                .bulletpoints {
                  margin: 16px 32px;
                  list-style: url('https://www.dacast.com/wp-content/uploads/2021/11/check_24px.svg');
                }
              }
            }
          }
          .rio-feature-bottom {
            .rio-feature-cta {
              h4 {
                ${heading06}
                ${medium}
              margin: 24px 0;
              }
              .button-container {
                justify-content: space-around;
                align-items: center;
                & > a:nth-child(1) {
                  ${dacastBtn}
                  ${dacastBtnSmall2}
                ${dacastBtnPrimary}
                }
                & > a:nth-child(2) {
                  ${dacastBtn}
                  ${dacastBtnSmall2}
                ${dacastBtnSecondary}
                }
              }
              .rio-feature-caption {
                text-align: left;
                ${caption}
              }
            }
            .rio-feature-extra {
              display: none;
            }
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        max-width: 560px;
        margin: -32px auto 32px auto;

        .rio-feature {
          margin: 180px auto 32px;
          .rio-feature-main {
            flex-direction: column;
            position: relative;
            .rio-feature-img {
              & > .feature-image {
                min-height: 320px !important;
              }
              & > .feature-image-icon {
                height: 62px !important;
                width: 62px !important;
              }
              &.copyright > .feature-image-icon {
                top: 0;
                left: 0;
              }
              &.experience > .feature-image-icon {
                top: 0;
                left: -16px;
              }
              &.stream > .feature-image-icon {
                top: 0;
                left: -16px;
              }
              &.china > .feature-image-icon {
                top: 0;
                right: 16px;
              }
            }
            .rio-feature-content {
              .rio-feature-title {
                ${heading03}
                ${medium}
              max-width: 560px;
                position: absolute;
                top: -120px;
                left: 0;
                right: 0;
                text-align: center;
              }
              .rio-feature-description {
                ${paragraph02}
                ${medium}
              margin-top: 32px;
                .bulletpoints {
                  margin: 16px 32px;
                  list-style: url('https://www.dacast.com/wp-content/uploads/2021/11/check_24px.svg');
                }
              }
            }
          }
          .rio-feature-bottom {
            display: flex;
            flex-direction: column;
            .rio-feature-cta {
              h4 {
                ${heading05}
                ${medium}
                > span {
                  color: var(--primary-500);
                }
                margin: 32px 0;
              }
              .button-container {
                justify-content: space-around;
                align-items: center;
                & > a:nth-child(1) {
                  ${dacastBtn}
                  ${dacastBtnLarge}
                  ${dacastBtnPrimary}
                }
                & > a:nth-child(2) {
                  ${dacastBtn}
                  ${dacastBtnLarge}
                  ${dacastBtnSecondary}
                }
              }
              .rio-feature-caption {
                text-align: center;
                ${caption}
              }
            }
            .rio-feature-extra {
              display: none;
            }
          }
        }
      }
      @media (min-width: 1024px) {
        max-width: 1200px;
        margin: 0px auto 75px auto;
        .rio-feature {
          margin: 75px 40px;
          &.right {
            .rio-feature-main {
              .rio-feature-img {
                order: 2;
                margin-left: 20px;
              }
              .rio-feature-content {
                order: 1;
              }
            }
            .rio-feature-bottom {
              justify-content: space-between;
              .rio-feature-cta {
                order: 2;
                flex: 0.5;
                padding-right: 0;
                padding-left: 35px;
              }
              .rio-feature-extra {
                order: 1;
                flex: 0.4;
              }
            }
          }
          .rio-feature-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-height: 860px;
            width: 100%;
            .rio-feature-content {
              max-width: 580px;
              .rio-feature-title {
                text-align: left;
                ${display02}
                ${medium}
              }
              .rio-feature-description {
                ${heading04}
                ${medium}
                  & > p {
                  margin-bottom: 16px;
                }
                margin-top: 32px;
                .bulletpoints {
                  margin-left: 48px;
                  margin-top: 16px;
                  list-style: url('https://www.dacast.com/wp-content/uploads/2022/03/check_32px.svg');
                }
                p#toggle {
                  display: none;
                }
              }
            }
          }
          .rio-feature-bottom {
            display: flex;
            flex-direction: row;
            margin-top: 52px;
            width: 100%;
            .rio-feature-cta {
              flex: 0.6;
              text-align: left;
              align-content: flex-end;
              padding-right: 103px;
              &.full {
                flex: 0.8;
              }
              h4 {
                order: 1;
                ${heading02}
                ${medium}
                > span {
                  color: var(--primary-500);
                }
              }
              .button-container {
                order: 3;
                margin-top: 32px;
                justify-content: flex-start;
                text-align: left;
                align-items: flex-start;
                & > a:nth-child(1) {
                  ${dacastBtn}
                  ${dacastBtnLarge}
                ${dacastBtnPrimary}
                margin: 0;
                }
                & > a:nth-child(2) {
                  ${dacastBtn}
                  ${dacastBtnLarge}
                ${dacastBtnSecondary}
                margin-left: 32px;
                }
              }
              .rio-feature-caption {
                text-align: left;
                ${heading05}
                ${medium}
                margin-top: 24px;
                order: 2;
              }
            }
            .rio-feature-extra {
              ${heading05}
              ${medium}
              flex: 0.4;
            }
          }
        }
      }
    }
    .rio-cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0;
      & > h4,
      & > h2 {
        display: none;
      }
      .rio-cards--container {
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        max-width: 1200px;
        .rio-card {
          display: flex;
          flex-direction: column;
          max-width: 580px;
          background: linear-gradient(116.16deg, #ffffff 0.56%, #fbfbfb 100%);
          box-sizing: border-box;
          box-shadow: 0px -8px 32px rgba(255, 255, 255, 0.25),
            0px 8px 32px #e5e5e5;
          border-radius: 24px;
          padding: 16px;
          border: 0;
          .rio-card--content {
            margin-top: 16px;
            br {
              display: none;
            }
            br:first-of-type {
              display: none;
            }
            .rio-card--text {
              > p {
                text-align: left;
                letter-spacing: -0.05em;
                line-height: 18px;
              }
              > p.dacast-p1,
              > p.dacast-p3 {
                display: none;
                margin: 0;
                &:nth-of-type(2) {
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        margin: 0 auto;
        justify-content: center;
        & > h2 {
          display: block;
          ${heading04s}
          ${bold}
          max-width: 300px;
          margin-bottom: 40px;
        }
        .rio-cards--container {
          justify-content: center;
          margin: 0 16px 64px;
          .rio-card {
            padding: 16px;
            width: 100%;
            margin: 16px 0;
            .rio-card--content {
              margin-top: 16px;
              .rio-card--title {
                margin-bottom: 16px;
                & > h4 {
                  display: block;
                }
              }
              .rio-card--text > p.dacast-p3 {
                display: block;
              }
            }
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        margin: 40px;
        & > h2 {
          display: block;
          ${heading02}
          ${bold}
        }
        .rio-cards--container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: minmax(min-content, max-content);
          justify-items: center;
          align-items: center;
          grid-row-gap: 40px;
          grid-column-gap: 21px;
          margin: 40px 0;
          .rio-card {
            padding: 24px;
            height: 100%;
            .rio-card--content {
              br:first-of-type {
                display: none;
              }
              .rio-card--title {
                padding: 30px 0;
              }
              .rio-card--text > p.dacast-p1 {
                display: block;
                &:nth-of-type(2) {
                  margin: 0px;
                }
                &:nth-of-type(4) {
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
      @media (min-width: 1024px) {
        padding: 0 0 78px;
        max-width: 1200px;
        margin: 150px auto;
        & > h2 {
          display: block;
          ${display02}
          ${bold}
          margin-bottom: 126px;
        }
        .rio-cards--container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: minmax(min-content, max-content);
          grid-row-gap: 40px;
          grid-column-gap: 43px;
          justify-items: center;
          margin: 0 40px;
          .rio-card {
            padding: 33px 64px;
            min-height: 410px;
            .rio-card--content {
              margin: 0;
              br:first-of-type {
                display: none;
              }
              .rio-card--title {
                padding: 30px 0;
              }
              .rio-card--text > p.dacast-p1 {
                display: block;
                line-height: 26px;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .rio-faq {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      .rio-section-faq {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        & > h2 {
          display: block;
          text-align: center;
        }
        max-width: 1200px;
      }
      .rio-accordion-item-faq {
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-direction: column;
        border-bottom: 1px solid var(--neutral-300);
        padding-right: 12px;
        width: 100%;
        > h5 {
          display: flex;
          ${subheading}
          ${medium}
          cursor: pointer;
          &::after {
            content: url('https://www.dacast.com/wp-content/uploads/2021/11/arrow-dropdown.svg');
            height: 12px;
            width: 12px;
            position: absolute;
            right: 0;
          }
        }
        &.active > h5:after {
          transform: scaleY(-1);
          top: 24px;
        }
        .rio-accordion-item-text {
          max-height: 0px;
          overflow: hidden;
          transition: max-height 1s ease-out;
          transition-delay: -0.8s;
          &.active {
            & > p {
              margin: 0;
            }
            max-height: 3000px;
            margin-bottom: 16px;
            transition: max-height 1s ease-in;
          }
        }
      }
      @media (max-width: 767px) {
        margin: 64px 16px 0;
        min-height: 800px;
        .rio-section-faq {
          & > h2 {
            text-align: center;
            margin-bottom: 34px;
            ${heading05s}
            ${medium}
          }
          .rio-accordion-item-faq {
            > h5 {
              ${paragraph02}
              ${medium}
              margin-bottom: 26px;
              padding: 10px 0;
            }
            &.active > h5:after {
              transform: scaleY(-1);
              top: 48px;
            }
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        margin: 60px auto 0;
        max-width: 671px;
        min-height: 800px;
        .rio-section-faq > h2 {
          ${heading02s}
          ${bold}
          margin-bottom: 26px;
        }
        .rio-accordion-item-faq {
          > h5 {
            ${subheading}
            ${medium}
          margin: 26px 0;
          }
        }
      }
      @media (min-width: 1024px) {
        margin: 150px auto 0;
        max-width: 785px;
        min-height: 800px;
        .rio-section-faq > h2 {
          ${heading01}
          ${medium}
          margin-bottom: 54px;
        }
        .rio-accordion-item-faq > h5 {
          ${subheading}
          ${medium}
          margin: 26px 0;
        }
      }
    }
    .rio-footer {
      display: flex;
      flex-direction: column;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      align-items: center;
      justify-content: center;
      padding: 0;
      .cta-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--shades-0);
        & > h5 {
          display: block;
          text-align: center;
        }
      }
      @media (max-width: 767px) {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/RioFooterMobile.png');
        margin-top: -100px;
        height: 254px;
        .cta-container {
          margin: 61px 16px 63px;
          max-width: 288px;
          > h5 {
            ${heading05s}
            ${bold}
            margin-bottom: 24px;
          }
          a {
            ${dacastBtn}
            ${dacastBtnLarge}
            ${dacastBtnPrimary}
          }
        }
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/RioFooterTablet.png');
        margin-top: -60px;
        height: 584px;
        .cta-container {
          margin: 202px 41px 122px;
          max-width: 752px;
          & > h5 {
            ${heading02}
            ${medium}
            margin-bottom: 24px;
          }
          & > a {
            ${dacastBtn}
            margin-top: 40px;
            ${dacastBtnJumbo}
            ${dacastBtnPrimary}
          }
        }
      }
      @media (min-width: 1024px) {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/RioFooterDesktop-scaled.jpg');
        margin-top: 80px;
        height: 536px;
        .cta-container {
          margin: 176px 201px 146px;
          & > h5 {
            max-width: 1084px;
            ${heading02}
            ${medium}
            margin-bottom: 24px;
          }
          & > a {
            ${dacastBtn}
            margin-top: 40px;
            ${dacastBtnJumbo}
            ${dacastBtnPrimary}
          }
        }
      }
    }
    .cornerstone-header {
      display: flex;
      flex-direction: column;
      .cornerstone-header-thumbnail {
        background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/VODhero-1.png');
        &.chinalive {
          background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/hero.png');
        }
        &.multicdn {
          background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/hero2.png');
        }
        &.webrtc {
          background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/hero3.png');
        }
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        height: 300px;
        background-position: center;
        @media (min-width: 768px) and (max-width: 1024px) {
          display: none;
        }
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        margin: 75px 32px;
      }
      @media (min-width: 1024px) {
        margin: 75px auto;
        justify-content: center;
      }
      @media (max-width: 767px) {
        justify-content: center;
        margin: 0 16px;
        .cornerstone-header-title {
          text-align: center;
          padding: 16px;
          > h2 {
            ${heading03s}
            ${medium}
          > span {
              color: var(--primary-500);
            }
          }
        }
        .cornerstone-header-thumbnail {
          display: flex;
          background-size: contain;
          background-repeat: no-repeat;
          height: 300px;
          background-position: center;
        }
        .cornerstone-header-content {
          ${paragraph03}
          > p:first-of-type {
            margin-bottom: 16px;
          }
        }
        .cornerstone-header-cta {
          margin: 16px auto;
          & > a {
            ${dacastBtn}
            ${dacastBtnMedium}
            ${dacastBtnPrimary}
          }
        }
      }
      @media (min-width: 768px) {
        max-width: 993px;
        justify-content: center;
        flex-wrap: wrap;
        height: 600px;
        .cornerstone-header-title {
          text-align: left;
          order: 1;
          max-width: 600px;
          margin-bottom: 16px;
          > h2 {
            ${display02}
            ${medium}
            > span {
              color: var(--primary-500);
            }
          }
        }
        .cornerstone-header-thumbnail {
          height: calc(100% - 100px);
          width: 300px;
          order: 4;
        }
        .cornerstone-header-content {
          ${subheading}
          > p:first-of-type {
            margin-bottom: 16px;
          }
          order: 2;
          max-width: 608px;
        }
        .cornerstone-header-cta {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          & > a {
            ${dacastBtn}
            ${dacastBtnJumbo}
            ${dacastBtnPrimary}
            margin: 40px 0px 0px;
          }
          order: 3;
        }
      }
    }
    .disclaimer {
      text-align: center;
    }
    .features {
      display: flex;
      flex-direction: column;
      .feature {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        .feature-title {
          display: flex;
        }
      }
      @media (max-width: 767px) {
        margin-top: 48px;
        .feature {
          flex-direction: column;
          margin: 8px 16px;
          & > .feature-title {
            & > i {
              display: none;
            }
            ${heading04s}
            ${medium}
          }
          & > .feature-description > p {
            margin-bottom: 0;
            margin-top: 16px;
          }
        }
      }
      @media (min-width: 768px) {
        margin: 0 40px;
        .feature {
          max-width: 993px;
          width: 100%;
          align-self: center;
          border-bottom: 1px solid #d4d4d4;
          margin: 10px 0;
          padding-bottom: 14px;
          & > .feature-title {
            ${heading04s}
            ${medium}
            margin-top: 5px;
            & > h2 {
              text-align: left;
              margin-left: 30px;
              min-width: 160px;
            }
          }
          & > .feature-description {
            max-width: 510px;
            margin-left: 16px;
            text-align: justify;
          }
        }
      }
    }
    .free-trial-CTA {
      display: flex;
      &.green {
        background-color: var(--success-50);
      }
      &.blue {
        background-color: var(--primary-50);
      }
      justify-content: center;
      align-items: center;
      .free-trial-CTA-text {
        display: flex;
        padding: 0 16px;
      }
      a {
        ${dacastBtn}
        ${dacastBtnPrimary}
        margin: 0;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        padding: 24px 16px;
        margin: 64px 0;
        .free-trial-CTA-text {
          flex-direction: column;
          text-align: center;
          & > h4 {
            ${heading04s}
            ${medium}
            & > span {
              color: var(--primary-500);
            }
          }
          & > h6 {
            display: none;
          }
          & > p {
            ${paragraph03}
            margin-top: 16px;
          }
        }
        a {
          ${dacastBtnSmall2}
          margin-top: 24px;
        }
      }
      @media (min-width: 768px) {
        max-width: 993px;
        margin: 75px auto;
        justify-content: space-around;
        padding: 24px 0;
        border-radius: 16px;
        .free-trial-CTA-text {
          flex-direction: column;
          text-align: left;
          & > h4 {
            ${heading04s}
            ${medium}
            max-width: 433px;
            & > span {
              color: var(--primary-500);
            }
          }
          & > h6 {
            ${heading06}
            ${medium}
            margin-top: 16px;
          }
          & > p {
            display: none;
          }
        }
        a {
          ${dacastBtnJumbo}
        }
      }
    }
    .vod-payment {
      display: flex;
      align-items: center;
      justify-content: center;
      .vod-payment-container {
        .vod-payment-title {
          text-align: center;
        }
        .vod-payment-content {
          display: flex;
          flex-direction: column;
          .vod-payment-icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      @media (max-width: 767px) {
        margin: 64px 16px 24px;
        .vod-payment-container {
          .vod-payment-title {
            ${heading04s}
            ${medium}
          margin-bottom: 30px;
          }
          .vod-payment-icon {
            margin: 20px 0;
          }
          .vod-payment-content {
            .vod-payment-content-text {
              .vod-payment-content-title {
                ${heading04s}
                ${medium}
            margin-top: 24px;
                margin-bottom: 16px;
              }
              .vod-payment-content-description {
                ${subheading}
              }
            }
          }
        }
      }
      @media (min-width: 768px) {
        justify-content: center;
        align-items: center;
        .vod-payment-container {
          margin: 0 40px;
          max-width: 993px;
          .vod-payment-title {
            ${heading01}
            ${medium}
          margin-bottom: 70px;
          }
          .vod-payment-content {
            .vod-payment-icon {
              margin: 0 50px;
            }
            flex-direction: row-reverse;
            justify-content: space-between;
            .vod-payment-content-text {
              .vod-payment-content-title {
                ${heading02}
                ${medium}
            margin-bottom: 16px;
              }
              .vod-payment-content-description {
                ${subheading}
                max-width: 580px;
              }
            }
          }
        }
      }
    }
    .cornerstone-ctas {
      display: flex;
      flex-direction: column;
      .cornerstone-cta-item {
        display: flex;
        flex-direction: column;
        .cornerstone-cta-item-thumbnail {
          position: relative;
          z-index: 10;
          max-width: 316px;
          &::after {
            content: ' ';
            display: block;
            position: absolute;
            width: 175px;
            height: 175px;
            left: 100px;
            top: 23px;
            border-radius: 50%;
            background: var(--warning-200);
            filter: blur(50px);
            z-index: -1;
            @media (min-width: 768px) and (max-width: 1024px) {
              display: none;
            }
          }
          &
            > div.gatsby-image-wrapper
            > div[style*='display: block;']
            > img[role='presentation'] {
            position: fixed !important;
            width: 1px !important;
            height: 1px !important;
          }
        }
      }
      @media (max-width: 900px) {
        .cornerstone-cta-item {
          margin: 32px 16px;
          .cornerstone-cta-item-title {
            ${heading04s}
            ${medium}
            width: 100%;
            text-align: center;
          }
          .cornerstone-cta-item-content {
            ${subheading}
          }
          .cornerstone-cta-item-thumbnail {
            margin: 32px auto;
            display: flex;
            justify-content: center;
            width: 100%;
            overflow: hidden;
            > img {
              height: 230px !important;
              object-fit: contain !important;
            }
          }
        }
      }
      @media (min-width: 901px) {
        max-width: 993px;
        margin: 0 auto;
        .cornerstone-cta-item {
          flex-wrap: wrap;
          max-height: 520px;
          margin: 40px 0;
          justify-content: space-evenly;
          text-align: left;
          & > .cornerstone-cta-item-thumbnail {
            height: 520px;
          }
          &.left {
            align-items: flex-end;
            text-align: left;
            & > .cornerstone-cta-item-title {
              order: 2;
            }
            & > .cornerstone-cta-item-content {
              order: 3;
            }
            & > .cornerstone-cta-item-thumbnail {
              order: 1;
              > img {
                min-height: 100% !important;
              }
              & > ::after {
                content: ' ';
                display: block;
                position: absolute;
                width: 175px;
                height: 175px;
                left: 166px;
                top: 0px;
                border-radius: 50%;
                background: var(--warning-200);
                filter: blur(50px);
                z-index: -1;
              }
            }
          }
          .cornerstone-cta-item-title {
            ${heading02}
            ${medium}
            text-align: left;
            width: 580px;
            order: 1;
            margin-bottom: 32px;
            > h2 {
              width: 100%;
              text-align: left;
            }
          }
          .cornerstone-cta-item-content {
            ${subheading}
            max-width: 580px;
            order: 2;
          }
          .cornerstone-cta-item-thumbnail {
            margin: 32px auto;
            display: flex;
            justify-content: center;
            min-height: 360px !important;
            order: 3;
            > img {
              height: 100% !important;
              object-fit: contain !important;
            }
          }
        }
      }
    }
    .cornerstone-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        margin: 64px 0;
        .cornerstone-list-title {
          ${heading04s}
          ${medium}
          text-align: center;
          max-width: 288px;
          & > h2 > span {
            color: var(--primary-500);
          }
        }
        .cornerstone-list-items {
          margin-top: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 37px;
          width: 100%;
          background-color: var(--success-50);
          .cornerstone-list-item {
            margin: 32px 0;
            ${heading06s}
            ${medium}
            list-style: url('https://www.dacast.com/wp-content/uploads/2022/03/check_24pxgreen-1.svg');
          }
          &.blue {
            background-color: var(--primary-50);
            .cornerstone-list-item {
              list-style: url('https://www.dacast.com/wp-content/uploads/2022/03/check_24px.svg');
            }
          }
          & > .cornerstone-list-thumbnail {
            display: none;
          }
        }
      }
      @media (min-width: 768px) {
        margin: 64px auto 150px;
        max-width: 993px;
        .cornerstone-list-title {
          ${heading01}
          ${medium}
          text-align: center;
          & > h2 > span {
            color: var(--primary-500);
          }
          margin-bottom: 90px;
        }
        .cornerstone-list-items {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 32px;
          background-color: var(--success-50);
          border-radius: 16px;
          position: relative;
          & > ul {
            width: 683px;
            & > li.cornerstone-list-item {
              ${heading04}
              ${medium}
            list-style: url('https://www.dacast.com/wp-content/uploads/2022/03/check_24pxgreen-1.svg');
              margin: 32px 48px;
              padding-left: 20px;
            }
          }
          & > .cornerstone-list-thumbnail {
            content: url('https://www.dacast.com/wp-content/uploads/2022/03/Image.png');
            &.chinalive {
              content: url('https://www.dacast.com/wp-content/uploads/2022/03/unsplash_cx-cNkx7f6Y.png');
            }
            &.multicdn {
              content: url('https://www.dacast.com/wp-content/uploads/2022/03/unsplash_cx-cNkx7f6Y-1.png');
            }
            height: 336px;
            position: absolute;
            top: 168px;
            right: -159px;
            @media (min-width: 768px) and (max-width: 1440px) {
              display: none;
            }
          }
        }
      }
    }
    .vod-content {
      display: flex;
      flex-direction: column;
      .vod-content-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .vod-content-header-shield {
          margin-top: 51px;
        }
        .vod-content-header-title {
          ${heading04s}
          ${medium}
          margin-top: 16px;
        }
      }
      .vod-content-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .vod-content-card {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 305px;
          background: #fbfbfb;
          box-sizing: border-box;
          box-shadow: 0px 8px 32px #edebea,
            0px -8px 32px rgba(255, 255, 255, 0.25);
          border-radius: 24px;
          .vod-content-card-img {
            border-radius: 24px 24px 0 0;
            height: 272px;
            background-size: cover;
            background-position: -20px;
            background-repeat: no-repeat;
            &.education {
              background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/VODcontent1.jpg');
            }
            &.sports {
              background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/VODcontent2.jpg');
              background-position: 0px;
            }
            &.music {
              background-image: url('https://www.dacast.com/wp-content/uploads/2022/03/VODcontent3-scaled.jpg');
            }
          }
          .vod-content-card-caption {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            ${heading05}
            ${medium}
            height: 102px;
            margin: 0 16px;
          }
        }
      }
      @media (max-width: 767px) {
        margin: 24px auto 48px;
        .vod-content-header {
          margin-bottom: 36px;
          background-color: #f2f4fe;
        }
        .vod-content-cards {
          .vod-content-card {
            margin: 16px 16px;
          }
        }
      }
      @media (min-width: 768px) {
        margin: 150px auto;
        .vod-content-header {
          margin-bottom: 80px;
          .vod-content-header-title {
            ${heading03}
            ${medium}
            margin-top: 24px;
          }
        }
        .vod-content-cards {
          margin: 0 40px;
          .vod-content-card:nth-child(1),
          .vod-content-card:nth-child(2) {
            margin-right: 40px;
          }
          .vod-content-card {
            margin-bottom: 16px;
          }
          .vod-content-card-caption {
          }
        }
      }
    }
    .vod-CTAs {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: var(--shades-0);
      .vod-CTAs-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        .vod-CTAs-banner {
          background-color: #f5f7fa;
          .vod-CTAs-banner-image {
            content: url('https://www.dacast.com/wp-content/uploads/2022/03/VODbanner.png');
            max-height: 425px;
            object-fit: contain;
          }
        }
        .vod-CTAs-card {
          display: flex;
          flex-direction: column;
          background-color: var(--shades-0);
        }
      }
      @media (max-width: 767px) {
        margin-top: 64px;
        .vod-CTAs-header {
          margin-bottom: 32px;
          .vod-CTAs-banner-caption {
            > h2 {
              ${heading04s}
              ${medium}
            }
            padding: 0 48px;
            margin-bottom: 14px;
          }
          .vod-CTAs-banner {
            padding: 42px 20px;
          }
        }
        .vod-CTAs-cards {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 32px;
          margin: 0 16px;
          justify-items: center;
          .vod-CTAs-card {
            background: linear-gradient(116.55deg, #fbfbfb 0%, #ffffff 100%);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 0px -8px 32px rgba(255, 255, 255, 0.25),
              0px 8px 32px #e5e5e5;
            border-radius: 24px;
            padding: 16px;
            .vod-CTAs-card-icon {
              margin: 0 auto;
            }
            .vod-CTAs-card-title {
              margin: 16px 0;
              ${heading04s}
              ${medium}
            }
            .vod-CTAs-card-text {
              text-align: left;
              > p {
                ${paragraph03}
                letter-spacing: 0;
              }
            }
          }
        }
      }
      @media (min-width: 768px) {
        margin-top: 150px;
        .vod-CTAs-header {
          margin-bottom: 32px;
          position: relative;
          .vod-CTAs-banner-caption {
            position: absolute;
            bottom: -120px;
            right: 50%;
            transform: translateX(50%);
            max-width: 994px;
            min-width: 540px;
            > h2 {
              ${heading01}
              ${medium}
            }
            margin-bottom: 14px;
          }
          .vod-CTAs-banner {
            padding: 71px 0 152px;
          }
        }
        .vod-CTAs-cards {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 40px;
          grid-row-gap: 32px;
          margin: 120px auto;
          justify-items: left;
          max-width: 1200px;
          padding: 0 40px;
          .vod-CTAs-card {
            max-width: 477px;
            .vod-CTAs-card-icon {
              text-align: left;
            }
            .vod-CTAs-card-title {
              ${heading05}
              ${medium}
              margin: 16px 0 32px 0;
              text-align: left;
            }
            .vod-CTAs-card-text {
              text-align: left;
              > p {
                ${paragraph03}
                letter-spacing: 0;
              }
            }
          }
        }
      }
    }
    .rio-copyright {
      margin: 150px auto;
      max-width: 1200px;
      & > .rio-img {
        width: 50%;
        float: right;
        text-align: center;
      }
      & > .rio-info {
        width: 50%;
        float: left;
        & > .dacast-subheading:first-child {
          margin-top: 32px;
          margin-bottom: 24px;
        }
      }
      .dacast-btn {
        margin-top: 32px;
      }
    }
    &.cornerstone {
      padding-bottom: 150px;
    }
  }
`;
