import React, {useMemo, useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {graphql, withPrefix} from 'gatsby';
import SEO from '../../components/seo';
import ParseHTML from '../../components/ParseHTML';
import {pricingStyles} from './styles/pricing';
import useScript from '../../hooks/use-script';
import useCurrentContent from '../../hooks/use-current-content';
import {Helmet} from 'react-helmet';
import Layout from '../Layout/index.js';
import {rioStyles} from './styles/rio';

const TYPE = {
  'live-streaming-pricing-plans': 'pricing',
  'book-a-demo': 'bookademo',
  testshortcodes: 'cornerstone',
  'live-event-streaming': 'cornerstone',
  'stream-live-video-on-your-website': 'cornerstone',
  'live-streaming-platform': 'cornerstone',
  'video-platform-for-agencies': 'cornerstone',
  'video-analytics': 'cornerstone',
  'streaming-solutions-tv-radio': 'cornerstone',
  'private-video-sharing': 'cornerstone',
  'secure-video-upload': 'cornerstone',
  'ott-platform-gatsby': 'cornerstone',
  'ott-platform': 'cornerstone', // 81962
  'private-video-sharing-gatsby': 'cornerstone',
  'secure-video-upload-gatsby': 'cornerstone',
  'pay-per-view-streaming': 'cornerstone',
  'why-dacast-to-broadcast-live': 'cornerstone',
  'all-device-video-player-broadcast-software': 'cornerstone',
  'streaming-solutions': 'cornerstone',
  'ovp-online-video-platform': 'cornerstone',
  'video-on-demand-vod': 'cornerstone',
  'online-video-hosting': 'cornerstone',
  'video-hosting-manager': 'cornerstone',
  'professional-services': 'cornerstone',
  contact: 'contact',
  riocomponents: 'riocomponents',
  umbrella: 'riocomponents',
  drm4vod: 'riocomponents cornerstone',
  chinalive: 'riocomponents cornerstone',
  multicdn: 'riocomponents cornerstone',
  webrtc: 'riocomponents cornerstone',
  rio: 'riocomponents',
  'streaming-in-china': 'riocomponents cornerstone',
  'webrtc-browser-based-streaming': 'riocomponents cornerstone',
  'multi-cdn': 'riocomponents cornerstone',
  'drm-for-vod': 'riocomponents cornerstone',
  unsubscribe: 'unsubscribe cornerstone',
};

const CornerstoneScript = 'static/cornerstones.js?v=0.07';
const FAQScript = 'static/FAQ.js?v=0.01';
const unsubscribeScript = 'static/unsubscribe.js?v=0.02';

const SCRIPTS = {
  'live-streaming-pricing-plans': 'static/main-pricing.js?v=1.52',
  'book-a-demo': 'static/main-bookademo.js?v=0.26',
  contact: 'static/main-contact.js?v=0.37',
  testshortcodes: CornerstoneScript,
  'live-event-streaming': CornerstoneScript,
  'stream-live-video-on-your-website': CornerstoneScript,
  'live-streaming-platform': CornerstoneScript,
  'video-platform-for-agencies': CornerstoneScript,
  'video-analytics': CornerstoneScript,
  'streaming-solutions-tv-radio': CornerstoneScript,
  'private-video-sharing': CornerstoneScript,
  'secure-video-upload': CornerstoneScript,
  'ott-platform-gatsby': CornerstoneScript,
  'ott-platform': CornerstoneScript, // 81962
  'private-video-sharing-gatsby': CornerstoneScript,
  'secure-video-upload-gatsby': CornerstoneScript,
  'pay-per-view-streaming': CornerstoneScript,
  'why-dacast-to-broadcast-live': CornerstoneScript,
  'all-device-video-player-broadcast-software': CornerstoneScript,
  'streaming-solutions': CornerstoneScript,
  'ovp-online-video-platform': CornerstoneScript,
  'video-on-demand-vod': CornerstoneScript,
  'online-video-hosting': CornerstoneScript,
  'video-hosting-manager': CornerstoneScript,
  'professional-services': CornerstoneScript,
  riocomponents: FAQScript,
  umbrella: FAQScript,
  drm4vod: FAQScript,
  chinalive: FAQScript,
  multicdn: FAQScript,
  webrtc: FAQScript,
  rio: FAQScript,
  'streaming-in-china': FAQScript,
  'webrtc-browser-based-streaming': FAQScript,
  'multi-cdn': FAQScript,
  'drm-for-vod': FAQScript,
  unsubscribe: unsubscribeScript,
};

const HEADER_HEIGHT = 240;
const StyledPage = styled.div`
  ${pricingStyles}
  ${rioStyles}
`;

const Page = ({
  data: {
    page: {
      edges: [
        {
          node: {content, seo, locale, translated, databaseId},
        },
      ],
    },
  },
  pageContext: {languageMapping, uri, language},
}) => {
  const type = TYPE[uri];
  const elemRef = useRef(null);
  useScript(withPrefix(SCRIPTS[uri]));

  const getContent = useCurrentContent([translated, content]);
  const [isVisibleHeader, setIsVisibleHeader] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (type !== 'pricing') return;
      const {currentTab, openPlans, planDetailsTableTop, pageYOffset} = window;
      const isPlanTab = currentTab === 'PLANS_TAB';
      if (elemRef.current) {
        const offsetPlanTable = elemRef.current.querySelector(
          '.Plans__PlansDetailsTable'
        ).offsetHeight;
        const iframeHeight = elemRef.current.querySelector('iframe')
          .clientHeight;

        const isVisible =
          isPlanTab &&
          openPlans &&
          pageYOffset > offsetPlanTable + HEADER_HEIGHT + planDetailsTableTop &&
          pageYOffset < offsetPlanTable + iframeHeight;
        setIsVisibleHeader(isVisible);
      }
    };
  }, []);

  const locales = useMemo(() => {
    if (translated) {
      [
        ...translated.map(({locale}) => ({
          locale: languageMapping[locale.id],
          lang: locale.id,
        })),
        {locale: languageMapping[locale.id], lang: locale.id},
      ];
    }
  }, [translated]);

  const canonical = useMemo(() => {
    if (seo.canonical) {
      const urls = {
        development: 'https://dev.dacast.com',
        production: 'https://www.dacast.com',
      };
      if (!seo.canonical.includes(urls.production)) {
        if (seo.canonical.includes('-gatsby')) {
          const newCanonical = seo.canonical.split('-gatsby')[0];
          return `${urls[process.env.NODE_ENV]}${
            language !== 'en' ? `/${language}` : ''
          }${newCanonical}`;
        }
        return `${urls[process.env.NODE_ENV]}${
          language !== 'en' ? `/${language}` : ''
        }${seo.canonical}`;
      } else {
        if (seo.canonical.includes('-gatsby')) {
          const newCanonical = seo.canonical.split('-gatsby')[0];
          return `${urls[process.env.NODE_ENV]}${
            language !== 'en' ? `/${language}` : ''
          }${newCanonical}`;
        } else {
          return `${seo.canonical}`;
        }
      }
    }
    return 'https://www.dacast.com';
  }, [seo]);

  return (
    <Layout type={type}>
      <Helmet>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <SEO
        seo={seo}
        lang={language}
        locales={locales}
        translated={translated}
      />
      <div className={`page id_${databaseId}`}></div>
      <StyledPage
        className={type}
        headerIsVisible={isVisibleHeader}
        ref={elemRef}
      >
        {ParseHTML(getContent(), language)}
      </StyledPage>
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($language: String!, $databaseId: Int) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: allWpPage(filter: {databaseId: {eq: $databaseId}}) {
      edges {
        node {
          id
          databaseId
          title
          slug
          status
          date
          locale {
            id
          }
          translated {
            id
            link
            content
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
          }
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          content
        }
      }
    }
  }
`;

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: {
            content: PropTypes.string,
            seo: PropTypes.shape({}),
            locale: PropTypes.shape({}),
            translated: PropTypes.array,
          },
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    databaseId: PropTypes.number,
    uri: PropTypes.string,
    languageMapping: PropTypes.object,
    content: PropTypes.string,
    slug: PropTypes.string,
    seo: PropTypes.shape,
    locale: PropTypes.shape,
    language: PropTypes.string,
    translated: PropTypes.shape,
  }),
};

export default Page;
