/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';
import {addNodeHtmlLinks} from '../utils/wordpress-gatsby';
import {useCurrentSeo} from '../hooks/use-current-content';

function SEO({seo: defaultSeo, lang, meta, translated, databaseId = ''}) {
  const getSeo = useCurrentSeo([translated, defaultSeo]);
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const seo = getSeo();
  const metaDescription = seo.metaDesc || site.siteMetadata.description;
  const defaultTitle = seo.title || site.siteMetadata.title;
  const defaultType = seo.opengraphType || 'website';
  const defaultLang = lang.replace(/_.+$/, '') || 'en';
  const defaultName = seo.opengraphSiteName || 'Dacast';
  const defaultPublisher = seo.opengraphPublisher || 'Dacast';
  const defaultPublishedTime = seo.opengraphPublishedTime || '';
  const defaultModifiedTime = seo.opengraphModifiedTime || '';
  var robots =
    `${seo.metaRobotsNoindex ? seo.metaRobotsNoindex : 'index'}` +
    ', ' +
    `${seo.metaRobotsNofollow ? seo.metaRobotsNofollow : 'follow'}` +
    ', max-snippet:-1, max-image-preview:large, max-video-preview:-1';
  if (process.env.GATSBY_ENV != 'production' || databaseId == 28856) {
    robots =
      'noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1, env:' +
      process.env.GATSBY_ENV +
      ';';
  }

  const robotsMeta = robots;
  const defaultSchema = addNodeHtmlLinks(
    seo.schema.raw || '{"@context": "http://schema.org"}'
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'environment',
          content: process.env.GATSBY_ENV,
        },
        {
          name: 'robots',
          content: robotsMeta,
        },
        {
          property: 'og:locale',
          content: defaultLang,
        },
        {
          property: 'og:type',
          content: defaultType,
        },
        {
          property: 'og:title',
          content: defaultTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: seo.canonical,
        },
        {
          property: 'og:site_name',
          content: defaultName,
        },
        {
          property: 'og:image',
          content:
            'https://www.dacast.com/wp-content/uploads/2019/07/logo-dacast.png',
        },
        {
          property: 'article:publisher',
          content: defaultPublisher,
        },
        {
          property: 'article:author',
          content: defaultPublisher,
        },
        {
          property: 'article:published_time',
          content: defaultPublishedTime,
        },
        {
          property: 'article:modified_time',
          content: defaultModifiedTime,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: defaultTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'facebook-domain-verification',
          content: 'j1a3k75wfguovaliwuf2wmde0ytqez',
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{defaultSchema}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;

SEO.propTypes = {
  seo: PropTypes.object,
  translated: PropTypes.arrayOf(PropTypes.object),
  databaseId: PropTypes.number,
};
