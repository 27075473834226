import {css} from 'styled-components';

export default css`
  .Btn {
    &:hover {
      box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
      outline: none;
      opacity: 1;
      color: white;
      box-shadow: inset 0 0 0 100px rgba(255, 255, 255, 0.2);
    }

    &.Btn--disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.4;
    }

    &.Plans__Btn--select-plan {
      padding: 14px;
      border-radius: 5px;
      color: white;
      text-align: center;
      cursor: pointer;

      h4 {
        margin-top: -1px;
        margin-bottom: 0px;
        font-size: 24px;
        color: white;
        font-weight: 500;
      }

      &.Plans__Btn--select-plan-current-plan {
        background: white;
        box-shadow: 0 0 0 1px inset ${({theme}) => theme.colors.gray4};
        cursor: default;
        pointer-events: none;

        h4 {
          color: ${({theme}) => theme.colors.gray4};
        }
      }
    }

    &.Plans__Btn--starter-bg {
      background: ${({theme}) => theme.colors.primaryLighter2};
    }

    &.Plans__Btn--event-bg {
      background: ${({theme}) => theme.colors.secondary};
    }

    &.Plans__Btn--scale-bg {
      background: ${({theme}) => theme.colors.primary};
    }

    &.Plans__Btn--custom-bg {
      background: ${({theme}) => theme.colors.secondaryDark};
    }
  }
`;
